
	import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { PropType } from 'vue';
	import { Supplier } from '@/modules/entities/types/entities';
	import { Actions, Getters } from '@/modules/entities/store/modules/suppliers/types/StoreTypes';
	import { AxiosPromise } from 'axios';
	import { JsonResource } from '@/core/types/Entities';
	import { namespace } from 'vuex-class';

	const suppliers = namespace('entities/suppliers');

	@Component
	export default class SupplierBankAccountsComponent extends Vue {
		@suppliers.Action(Actions.EDIT_INSPECTED_SUPPLIER_BY_ID) editSupplier!: (payload: Supplier) => Promise<AxiosPromise<JsonResource<Supplier>>>;

		@suppliers.Getter(Getters.GET_SUPPLIER_DETAILS) storeSupplier!: Supplier;

		@PropSync('supplier', { type: Object as PropType<Supplier>, required: true }) supplierSync!: Supplier;

		state = {
			currentlyEditedAccountNumberIndex: -1,
			copiedToClipboard: false,
			errorCopyingToClipboard: false
		};

		addBankAccount(): void {
			const supplier: Supplier = JSON.parse(JSON.stringify(this.supplierSync));
			supplier.bankAccounts.push({ id: -1, accountNumber: '0000 0000 0000 0000', isMain: !supplier.bankAccounts.length });
			this.editSupplierThenChangeIndex(supplier);
		}

		removeBankAccount(idx: number): void {
			const supplier = JSON.parse(JSON.stringify(this.supplierSync));
			supplier.bankAccounts.splice(idx, 1);
			this.editSupplierThenChangeIndex(supplier);
		}

		editSupplierThenChangeIndex(supplier?: Supplier): void {
			this.editSupplier(supplier || this.supplierSync).then(() => this.state.currentlyEditedAccountNumberIndex = -1);
		}

		copyAccountNumberToClipboard(idx: number): void {
			const accountNumber = this.supplierSync.bankAccounts[idx].accountNumber;
			navigator.clipboard.writeText(accountNumber).then(() => {
				this.state.copiedToClipboard = true;
				setTimeout(() => {
					this.state.copiedToClipboard = false;
				}, 2000);
			}).catch(() => {
				this.state.errorCopyingToClipboard = true;
			})
		}

		@Watch('state.currentlyEditedAccountNumberIndex')
		onCurrentlyEditedAccountNumberIndexChange(newVal: number): void {
			if (newVal === -1) this.supplierSync = JSON.parse(JSON.stringify(this.storeSupplier));
		}
	}
